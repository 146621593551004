
ul {
    list-style: square outside;
}

ul,
ol {
    margin-left: var(--space-1);
    margin-bottom: 1em;
    margin-top: 1em;
    padding-left: var(--space-3);
}

li > {

    ul,
    ol {
        margin-left: var(--space-2);
        margin-top: 0;
        margin-bottom: 0;

    }

}



dt {
    font-weight: bolder;
}

dd {
    margin-bottom: var(--space-2);
}
