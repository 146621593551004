/* Basscss Margin */

.m0  { margin:        0 }
.mt0 { margin-top:    0 }
.mr0 { margin-right:  0 }
.mb0 { margin-bottom: 0 }
.ml0 { margin-left:   0 }
.mx0 { margin-left:   0; margin-right:  0 }
.my0 { margin-top:    0; margin-bottom: 0 }

.m1  { margin:        var(--space-1) }
.mt1 { margin-top:    var(--space-1) }
.mr1 { margin-right:  var(--space-1) }
.mb1 { margin-bottom: var(--space-1) }
.ml1 { margin-left:   var(--space-1) }
.mx1 { margin-left:   var(--space-1); margin-right:  var(--space-1) }
.my1 { margin-top:    var(--space-1); margin-bottom: var(--space-1) }

.m2  { margin:        var(--space-2) }
.mt2 { margin-top:    var(--space-2) }
.mr2 { margin-right:  var(--space-2) }
.mb2 { margin-bottom: var(--space-2) }
.ml2 { margin-left:   var(--space-2) }
.mx2 { margin-left:   var(--space-2); margin-right:  var(--space-2) }
.my2 { margin-top:    var(--space-2); margin-bottom: var(--space-2) }

.m3  { margin:        var(--space-3) }
.mt3 { margin-top:    var(--space-3) }
.mr3 { margin-right:  var(--space-3) }
.mb3 { margin-bottom: var(--space-3) }
.ml3 { margin-left:   var(--space-3) }
.mx3 { margin-left:   var(--space-3); margin-right:  var(--space-3) }
.my3 { margin-top:    var(--space-3); margin-bottom: var(--space-3) }

.m4  { margin:        var(--space-4) }
.mt4 { margin-top:    var(--space-4) }
.mr4 { margin-right:  var(--space-4) }
.mb4 { margin-bottom: var(--space-4) }
.ml4 { margin-left:   var(--space-4) }
.mx4 { margin-left:   var(--space-4); margin-right:  var(--space-4) }
.my4 { margin-top:    var(--space-4); margin-bottom: var(--space-4) }

.mxn1 { margin-left: -var(--space-1); margin-right: -var(--space-1); }
.mxn2 { margin-left: -var(--space-2); margin-right: -var(--space-2); }
.mxn3 { margin-left: -var(--space-3); margin-right: -var(--space-3); }
.mxn4 { margin-left: -var(--space-4); margin-right: -var(--space-4); }

.ml-auto { margin-left: auto }
.mr-auto { margin-right: auto }
.mx-auto { margin-left: auto; margin-right: auto; }



.mb-n35 { margin-bottom: -3.5em; }
