/*
  TEXT ALIGN
*/

.tl  { text-align: left; }
.tr  { text-align: right; }
.tc  { text-align: center; }

@media (--lap) {
  .tl-lap  { text-align: left; }
  .tr-lap  { text-align: right; }
  .tc-lap  { text-align: center; }
}

@media (--lap-and-up) {
  .tl-lap-and-up  { text-align: left; }
  .tr-lap-and-up  { text-align: right; }
  .tc-lap-and-up  { text-align: center; }
}

@media (--desk) {
  .tl-desk  { text-align: left; }
  .tr-desk  { text-align: right; }
  .tc-desk  { text-align: center; }
}
