/* Basscss Position */

.relative { position: relative }
.absolute { position: absolute }
.fixed    { position: fixed }

.bpx-100 { background-position-x: 100%; }
.bpx-75 { background-position-x: 75%; }
.bpx-50 { background-position-x: 50%; }

.bpy-50 { background-position-y: 50%; }

.top-n35    { top: -3.5em }

.top-0    { top: 0 }
.right-0  { right: 0 }
.bottom-0 { bottom: 0 }
.left-0   { left: 0 }

.z1 { z-index: var(--z1) }
.z2 { z-index: var(--z2) }
.z3 { z-index: var(--z3) }
.z4 { z-index: var(--z4) }

:root {
  --z1: 1;
  --z2: 2;
  --z3: 3;
  --z4: 4;
}
