/*------------------------------------*\
    #SITE-NAV
\*------------------------------------*/

.c-site-nav {}
    .c-site-nav__home {}
    .c-site-nav__logo {
        width: 120px;
        height: auto;
    }
    .c-site-nav__list {}
        .c-site-nav__link {
            @media (--palm) {
                margin-left: .5rem;
                margin-bottom: .75rem;
                padding: 0.125rem;
                display: inline-block;
                transition: all .25s ease;
            }
            @media (--lap-and-up) {
                margin-left: .5rem;
                transition: all .25s ease;
            }
        }

        .c-site-nav__link:hover,
        .c-site-nav__link:focus {
            @media (--lap-and-up) {
                box-shadow: 0 2px 0 0 var(--brand-green);
            }
        }



 .c-nav__link--active {}
 .c-nav__link--current {
    @media (--palm) {
        box-shadow: 0 2px 0 0 var(--brand-green-alt);
    }
    @media (--lap-and-up) {
        box-shadow: 0 2px 0 0 var(--brand-green-alt);
    }
 }

  .site-nav__home {
        display: block;
        float: left;
    }

        .site-nav__logo {
            display: block;
        }


        .c-site-nav__list {
            margin:  0;
            padding: 0;
            list-style: none;
        }
