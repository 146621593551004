/*
  BASE
*/


.c-page-head {
    background: var(--white);
    padding: 1em 0;
    @media (--lap-and-up) {
        background: url(../images/masthead.jpg) top center #fcfdfc;
        background-size: cover;
        padding: 1.5em;
    }
}

.c-page-head--masthead {
    background: url(../images/masthead.jpg) top center #fcfdfc;
    background-size: cover;
    padding-bottom: 3.5em;
    @media (--lap-and-up) {
        padding-bottom: 3.5em;
    }
}


.c-page-head__text {

}
