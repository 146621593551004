/* Basscss Padding */

.p0  { padding: 0 }
.pt0 { padding-top: 0 }
.pr0 { padding-right: 0 }
.pb0 { padding-bottom: 0 }
.pl0 { padding-left: 0 }
.px0 { padding-left: 0; padding-right:  0 }
.py0 { padding-top: 0;  padding-bottom: 0 }

.p1  { padding:        var(--space-1) }
.pt1 { padding-top:    var(--space-1) }
.pr1 { padding-right:  var(--space-1) }
.pb1 { padding-bottom: var(--space-1) }
.pl1 { padding-left:   var(--space-1) }
.py1 { padding-top:    var(--space-1); padding-bottom: var(--space-1) }
.px1 { padding-left:   var(--space-1); padding-right:  var(--space-1) }

.p2  { padding:        var(--space-2) }
.pt2 { padding-top:    var(--space-2) }
.pr2 { padding-right:  var(--space-2) }
.pb2 { padding-bottom: var(--space-2) }
.pl2 { padding-left:   var(--space-2) }
.py2 { padding-top:    var(--space-2); padding-bottom: var(--space-2) }
.px2 { padding-left:   var(--space-2); padding-right:  var(--space-2) }

.p3  { padding:        var(--space-3) }
.pt3 { padding-top:    var(--space-3) }
.pr3 { padding-right:  var(--space-3) }
.pb3 { padding-bottom: var(--space-3) }
.pl3 { padding-left:   var(--space-3) }
.py3 { padding-top:    var(--space-3); padding-bottom: var(--space-3) }
.px3 { padding-left:   var(--space-3); padding-right:  var(--space-3) }

.p4  { padding:        var(--space-4) }
.pt4 { padding-top:    var(--space-4) }
.pr4 { padding-right:  var(--space-4) }
.pb4 { padding-bottom: var(--space-4) }
.pl4 { padding-left:   var(--space-4) }
.py4 { padding-top:    var(--space-4); padding-bottom: var(--space-4) }
.px4 { padding-left:   var(--space-4); padding-right:  var(--space-4) }

.p5  { padding:        var(--space-5) }
.pt5 { padding-top:    var(--space-5) }
.pr5 { padding-right:  var(--space-5) }
.pb5 { padding-bottom: var(--space-5) }
.pl5 { padding-left:   var(--space-5) }
.py5 { padding-top:    var(--space-5); padding-bottom: var(--space-5) }
.px5 { padding-left:   var(--space-5); padding-right:  var(--space-5) }

.p6  { padding:        var(--space-6) }
.pt6 { padding-top:    var(--space-6) }
.pr6 { padding-right:  var(--space-6) }
.pb6 { padding-bottom: var(--space-6) }
.pl6 { padding-left:   var(--space-6) }
.py6 { padding-top:    var(--space-6); padding-bottom: var(--space-6) }
.px6 { padding-left:   var(--space-6); padding-right:  var(--space-6) }


@media (--palm) {
    .p0-palm  { padding: 0 }
    .pt0-palm { padding-top: 0 }
    .pr0-palm { padding-right: 0 }
    .pb0-palm { padding-bottom: 0 }
    .pl0-palm { padding-left: 0 }
    .px0-palm { padding-left: 0; padding-right:  0 }
    .py0-palm { padding-top: 0;  padding-bottom: 0 }
}
