/* Basscss Hide */

.hide {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

@each $breakpoint in -palm, -lap, -lap-and-up, -desk, -sofa {
    @media (-$(breakpoint)) {
        .hide$(breakpoint) {
            position: absolute !important;
            height: 1px;
            width: 1px;
            overflow: hidden;
            clip: rect(1px, 1px, 1px, 1px);
        }
    }
}



.display-none { display: none !important }

@each $breakpoint in -palm, -lap, -lap-and-up, -desk, -sofa {
    @media (-$(breakpoint)) {
        .display-none$(breakpoint) { display: none !important; }
    }
}
