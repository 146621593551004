/* Basscss Layout */

.inline       { display: inline }
.block        { display: block }
.inline-block { display: inline-block }
.table        { display: table }
.table-cell   { display: table-cell }

.overflow-hidden { overflow: hidden }
.overflow-scroll { overflow: scroll }
.overflow-auto   { overflow: auto }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table
}
.clearfix:after { clear: both }

.left  { float: left }
.right { float: right }

.fit { max-width: 100% }

.max-width-1 { max-width: var(--width-1) }
.max-width-2 { max-width: var(--width-2) }
.max-width-3 { max-width: var(--width-3) }
.max-width-4 { max-width: var(--width-4) }

.border-box { box-sizing: border-box }

:root {
  --width-1: 24rem;
  --width-2: 32rem;
  --width-3: 48rem;
  --width-4: 64rem;
}




/*
  GRID
*/

/* .container {
  max-width: var(--container-width);
  margin-left: auto;
  margin-right: auto;
} */


@lost gutter 18px;

.o-layout {
    lost-utility: clearfix;
}

.o-layout__item {}

.o-layout__item--1\/1 { lost-column: 1/1;}
.o-layout__item--1\/2 { lost-column: 1/2;}
.o-layout__item--1\/3 { lost-column: 1/3;}
.o-layout__item--2\/3 { lost-column: 2/3;}
.o-layout__item--1\/4 { lost-column: 1/4;}
.o-layout__item--3\/4 { lost-column: 3/4;}

@each $breakpoint in -lap, -lap-and-up, -desk, -sofa {
    @media (-$(breakpoint)) {
        .o-layout__item--1\/1$(breakpoint) { lost-column: 1/1;}
        .o-layout__item--1\/2$(breakpoint) { lost-column: 1/2;}
        .o-layout__item--1\/3$(breakpoint) { lost-column: 1/3;}
        .o-layout__item--2\/3$(breakpoint) { lost-column: 2/3;}
        .o-layout__item--1\/4$(breakpoint) { lost-column: 1/4;}
        .o-layout__item--3\/4$(breakpoint) { lost-column: 3/4;}
    }
}


.o-fb {
    lost-flex-container: row;
}

.o-fb__item {}

.o-fb__item--1\/1 { lost-column: 1/1 flex;}
.o-fb__item--1\/2 { lost-column: 1/2 flex;}
.o-fb__item--1\/3 { lost-column: 1/3 flex;}
.o-fb__item--2\/3 { lost-column: 2/3 flex;}
.o-fb__item--1\/4 { lost-column: 1/4 flex;}
.o-fb__item--3\/4 { lost-column: 3/4 flex;}

@each $breakpoint in -lap, -lap-and-up, -desk, -sofa {
    @media (-$(breakpoint)) {
        .o-fb__item--1\/1$(breakpoint) { lost-column: 1/1 flex;}
        .o-fb__item--1\/2$(breakpoint) { lost-column: 1/2 flex;}
        .o-fb__item--1\/3$(breakpoint) { lost-column: 1/3 flex;}
        .o-fb__item--2\/3$(breakpoint) { lost-column: 2/3 flex;}
        .o-fb__item--1\/4$(breakpoint) { lost-column: 1/4 flex;}
        .o-fb__item--3\/4$(breakpoint) { lost-column: 3/4 flex;}
    }
}
