h1 {
    font-size: 2rem;
    font-weight: 300;
}
h2 {
    font-size: 1.75rem;
    font-weight: 300;
}
h3 {
    font-size: 1.5rem;
    font-weight: 300;
}
h4 {
    font-size: 1.25rem;
    font-weight: 300;
}
h5 {
    font-size: 1rem;
    font-weight: 300;
}
h6 {
    font-size: .875rem;
    font-weight: 300;
}
