/*

   CLEARFIX

*/

/* Nicolas Gallaghers Clearfix solution
   Ref: http://nicolasgallagher.com/micro-clearfix-hack/ */

.cf:before,
.cf:after { content: " "; display: table; }
.cf:after { clear: both; }
.cf {       *zoom: 1; }

.cl { clear: left; }
.cr { clear: right; }
.cb { clear: both; }
.cn { clear: none; }

@media (--palm) {
  .cl-lap { clear: left; }
  .cr-lap { clear: right; }
  .cb-lap { clear: both; }
  .cn-lap { clear: none; }
}

@media (--desk) {
  .cl-desk { clear: left; }
  .cr-desk { clear: right; }
  .cb-desk { clear: both; }
  .cn-desk { clear: none; }
}

@media (--sofa) {
  .cl-sofa { clear: left; }
  .cr-sofa { clear: right; }
  .cb-sofa { clear: both; }
  .cn-sofa { clear: none; }
}
