/*

   FONT FAMILY GROUPS

*/

.source-sans-pro {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont,
               'avenir next', avenir,
               helvetica, 'helvetica neue',
               ubuntu,
               roboto, noto,
               'segoe ui', arial,
               sans-serif;
}

.sans-serif {
  font-family: -apple-system, BlinkMacSystemFont,
               'avenir next', avenir,
               helvetica, 'helvetica neue',
               ubuntu,
               roboto, noto,
               'segoe ui', arial,
               sans-serif;
}

.serif {
  font-family: georgia,
               times,
               serif;
}

.system-sans-serif {
  font-family: sans-serif;
}

.system-serif {
  font-family: serif;
}


/* Monospaced Typefaces (for code) */

/* From http://cssfontstack.com */
code, .code {
  font-family: Consolas,
               monaco,
               monospace;
}


/* Sans-Serif Typefaces */

.helvetica {
  font-family: 'helvetica neue', helvetica,
               sans-serif;
}


/* Serif Typefaces */

.georgia {
  font-family: georgia,
               serif;
}

.times {
  font-family: times,
               serif;
}

.bodoni {
  font-family: "Bodoni MT",
                serif;
}

.calisto {
  font-family: "Calisto MT",
                serif;
}

.garamond {
  font-family: garamond,
               serif;
}
