.c-card {
    background-color: var(--white);
    margin-bottom: 1.125em;
    padding: var(--space-2);
    @media (--lap-and-up) {
        padding: var(--space-3);
    }
}

    .c-card--gradient {
        position: relative;
        background-size: cover;
    }
    .c-card--gradient:before {
        background: linear-gradient(to bottom, var(--transparent) 40%,var(--brand-gray) 100%);
        content: "";
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
    }

    .c-card--gradient:after {
        content: "";
        position: relative;
    }

    .c-card--usp {
        position: relative;

        & .c-card__label {
            position: absolute;
            top: 0; left: 0;
            margin: .5em;
            width: 1.25em;
            height: 1.25em;
            @media (--lap-and-up) {
                margin: 1em;
                width: 1.75em;
                height: 1.75em;
            }
            background: var(--black-05);
            border-radius: 2em;
        }

            & .c-card__label--blue {
                background: #acc1e5;
                box-shadow: inset 0 0 10px 5px #29487f;
            }
            & .c-card__label--yellow {
                background: #ffe490;
                box-shadow: inset 0 0 10px 5px #a27a13;
            }
            & .c-card__label--orange {
                background: #f6b88e;
                box-shadow: inset 0 0 10px 5px #b15719;
            }
            & .c-card__label--green {
                background: #b0d39a;
                box-shadow: inset 0 0 10px 5px #49702f;
            }

        & .c-card__content {
            margin-left: 1em;
        }
    }
