.media {
  display: flex;
  align-items: flex-start;
}

.media-figure {
  margin-right: 1em;
}

.media-body {
  flex: 1;
}
