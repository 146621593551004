/*
  Page foot
*/


.c-page-foot a {
    color: var(--white);

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
