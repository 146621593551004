.o-wrapper {
    max-width: 62em;
    margin-right: auto;
    margin-left:  auto;
    padding-right: 1rem;
    padding-left:  1rem;
}

.o-wrapper--small {
    max-width: 42em;
}
