.c-cover {
    margin: 0;
    margin-bottom: 1.125em;
    position: relative;
}

    .c-cover__caption {
        @media (--lap-and-up) {
            position: absolute;
            top: 0;
            right: 0;
            text-align: right;
            padding: 2em;
            font-size: 1.25em;
            letter-spacing: .0625em;
            max-width: 70%;

            & p {
                margin: 0;
            }
        }
        @media (--desk) {
            max-width: 50%;
        }
    }


.c-cover-alt {
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: 50% 50%;
}
