.c-client-list {
    margin-right:  0;
    margin-left:  0;
    padding: 0;
    list-style: none;
    text-align: center;
}


    .c-client-list__item {
        display: inline-block;
        padding-top: var(--space-2);
        padding-right: var(--space-2);
        padding-bottom: var(--space-2);
        padding-left: var(--space-2);

        @media (--lap-and-up) {
            padding-top: var(--space-3);
            padding-right: var(--space-2);
            padding-bottom: var(--space-3);
            padding-left: var(--space-2);
        }

    }

        .c-client-list__link {
            display: block;
            opacity: 1;
            transition: opacity .5s ease-out;

            &:hover,
            &:focus {
                opacity: .75;
                transition: opacity .25s ease;
            }
        }

            .c-client-list__logo {
                display: block;
            }
