/*

   TYPE SCALE

*/

/* For Hero Titles */
.f-6,
.f-headline {
  font-size: 6rem;
}
.f-5,
.f-subheadline {
  font-size: 5rem;
}

/* Type Scale */
.f1 { font-size: 2rem; }
.f2 { font-size: 1.75rem; }
.f3 { font-size: 1.5rem; }
.f4 { font-size: 1.25rem; }
.f5 { font-size: 1rem; }
.f6 { font-size: .875rem; }

@media (--lap) {
  .f-6-lap,
  .f-headline-lap { font-size: 6rem; }
  .f-5-lap,
  .f-subheadline-lap { font-size: 5rem; }
  .f1-lap { font-size: 3rem; }
  .f2-lap { font-size: 2.25rem; }
  .f3-lap { font-size: 1.5rem; }
  .f4-lap { font-size: 1.25rem; }
  .f5-lap { font-size: 1rem; }
  .f6-lap { font-size: .875rem; }
}

@media (--lap-and-up) {
  .f-6-lap-and-up,
  .f-headline-lap-and-up { font-size: 6rem; }
  .f-5-lap-and-up,
  .f-subheadline-lap-and-up { font-size: 5rem; }
  .f1-lap-and-up { font-size: 3rem; }
  .f2-lap-and-up { font-size: 2.25rem; }
  .f3-lap-and-up { font-size: 1.5rem; }
  .f4-lap-and-up { font-size: 1.25rem; }
  .f5-lap-and-up { font-size: 1rem; }
  .f6-lap-and-up { font-size: .875rem; }
}

@media (--desk) {
  .f-6-desk,
  .f-headline-desk {
    font-size: 6rem;
  }
  .f-5-desk,
  .f-subheadline-desk {
    font-size: 5rem;
  }
  .f1-desk { font-size: 3rem; }
  .f2-desk { font-size: 2.25rem; }
  .f3-desk { font-size: 1.5rem; }
  .f4-desk { font-size: 1.25rem; }
  .f5-desk { font-size: 1rem; }
  .f6-desk { font-size: .875rem; }
}
