.cookie-bar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    background-color: var(--white);
    padding: 1em 0;

    &:before,
    &:after { content: " "; display: table; }
    &:after { clear: both; }
    *zoom: 1;
}
    .cookie-bar__wrap {
        max-width: 62em;
        margin-right: auto;
        margin-left:  auto;
        padding-right: 1rem;
        padding-left:  1rem;

        display: table;
        vertical-align: middle;
    }
        .cookie-bar__message {
            width: 75%;
            display: table-cell;
        }

            .cookie-bar__title {
                @media (--lap-and-up) { display: none; }
                margin: 0;
            }

            .cookie-bar__text {
                @media (--palm) { display: none; }
                margin: 0;
            }

        .cookie-bar__remove {
            width: 25%;
            display: table-cell;
            vertical-align: middle;

            @media (--palm) { text-align: center; }
            @media (--lap-and-up) { text-align: right; }
        }

    .cookie-bar__link {}

    .cookie-bar__button {
        border: 0;
        border-radius: .5em;
        color: var(--white);
        display: inline-block;
        letter-spacing: .15em;
        padding: .5em 1em;
        text-decoration: none;
        transition: all .25s ease;
        background-color: var(--brand-green);

        @media (--palm) { margin-left: .25em; }

        &:hover,
        &:focus {
            background-color: var(--brand-green-alt);
            color: var(--white);
            cursor: pointer;
        }
    }
