.c-article {
    @media (--palm) {
        padding-top: var(--space-2);
        padding-bottom: var(--space-2);
    }
    @media (--lap) {
        padding-top: var(--space-3);
        padding-bottom: var(--space-3);
    }
    @media (--desk) {
        padding-top: var(--space-4);
        padding-bottom: var(--space-4);
    }
}
