.c-cta {
    padding-top: 4em;
    padding-bottom: 4em;
}

.c-cta__title {
    margin-top: 0;
    margin-bottom: .5em;
}

