/* Basscss Forms */

.label {
    font-size: 1.25rem;
    display: block;
    margin-bottom: .5rem;
}

.input {
    font-family: inherit;
    font-size: 1.25rem;
    display: block;
    width: 100%;
    height: 4rem;
    padding: 1rem;
    margin-bottom: var(--space-3);
    border-radius: 0;
    box-sizing: border-box;
    border: 1px solid var(--brand-gray);
    box-shadow: inset 0 0 0 2px var(--light-gray);
    transition: box-shadow .25s ease, border .25s ease;
}
.input:hover,
.input:focus {
    border-color: var(--brand-dark-gray);
    box-shadow: inset 0 0 0 1px var(--brand-dark-gray),
                inset 0 0 0 2px var(--light-gray);
    outline: none;
}

.select {
    font-family: inherit;
    font-size: inherit;
    display: block;
    width: 100%;
    height: 2.5rem;
    padding: .5rem;
    margin-bottom: 1rem;
    border-radius: 0;
    box-sizing: border-box;
    border: 1px solid var(--brand-gray);
    box-shadow: inset 0 0 0 2px var(--light-gray);
    transition: box-shadow .25s ease, border .25s ease;
}
.select:hover,
.select:focus {
    border-color: var(--brand-dark-gray);
    box-shadow: inset 0 0 0 1px var(--brand-dark-gray),
                inset 0 0 0 2px var(--light-gray);
    outline: none;
}

.textarea {
    font-family: inherit;
    font-size: 1.25rem;
    display: block;
    width: 100%;
    padding: 1rem;
    margin-bottom: var(--space-3);
    border-radius: 0;
    box-sizing: border-box;
    resize: vertical;
    border: 1px solid var(--brand-gray);
    box-shadow: inset 0 0 0 2px var(--light-gray);
    transition: box-shadow .25s ease, border .25s ease;
}
.textarea:hover,
.textarea:focus {
    border-color: var(--brand-dark-gray);
    box-shadow: inset 0 0 0 1px var(--brand-dark-gray),
                inset 0 0 0 2px var(--light-gray);
    outline: none;
}

.is-required:after {
    color: var(--red);
    content: attr(data-label-required);
    padding-left: .25rem;
    font-size: .8rem;
    float: right;
    padding-top: .5rem;
}

.is-optional:after {
    color: var(--teal);
    content: attr(data-label-optional);
    padding-left: .25rem;
    font-size: 1rem;
    float: right;
    padding-top: .25rem;
}

.is-error,
.is-error:hover,
.is-error:focus {
    border-color: var(--red);
    box-shadow: inset 0 0 0 1px var(--red),
                inset 0 0 0 2px var(--light-gray);
    margin-bottom: 0;

}

.is-success,
.is-success:hover,
.is-success:focus {
    border-color: var(--brand-green);
    box-shadow: inset 0 0 0 1px var(--brand-green),
                inset 0 0 0 2px var(--light-gray);
}

.errors-list {
    margin-top: var(--space-1);
    margin-bottom: var(--space-3);
}

.parsley-custom-error-message:before,
.parsley-required:before,
.parsley-type:before {
    content: "\2191";
    position: relative;
    top: -0.125em;
    left: -0.25em;
}
