/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

:root {
    --hamburger-padding:                            1.25em 0 1em 1em;
    --hamburger-layer-width:                        2.5em;
    --hamburger-layer-height:                       .25em;
    --hamburger-layer-spacing:                      .375em;
    --hamburger-layer-color:                        var(--black);
    --hamburger-layer-border-radius:                4px;
    --hamburger-hover-opacity:                      0.7;
    --hamburger-hover-transition-duration:          0.15s;
    --hamburger-hover-transition-timing-function:   linear;
}

/* Base Hamburger (We need this) */
/* ================================================== */
.c-hamburger {
    padding: var(--hamburger-padding);
    display: inline-block;
    cursor: pointer;
    outline: none;

    transition-property: opacity, filter;
    transition-duration: var(--hamburger-hover-transition-duration);
    transition-timing-function: var(--hamburger-hover-transition-timing-function);

    /* Normalize (<button>) */
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    &:hover {
        opacity: var(--hamburger-hover-opacity);
    }
}

.c-hamburger__box {
    width: var(--hamburger-layer-width);
    height: calc( var(--hamburger-layer-height) * 3 + var(--hamburger-layer-spacing) * 2 );
    display: inline-block;
    position: relative;
}

.c-hamburger__inner {
    display: block;
    top: 50%;
    margin-top: calc( var(--hamburger-layer-height) / -2 );

    &,
    &::before,
    &::after {
        width: var(--hamburger-layer-width);
        height: var(--hamburger-layer-height);
        background-color: var(--hamburger-layer-color);
        border-radius: var(--hamburger-layer-border-radius);
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    &::before,
    &::after {
        content: "";
        display: block;
    }

    &::before {
        top: calc( ( var(--hamburger-layer-spacing) + var(--hamburger-layer-height) ) * -1 );
    }

    &::after {
        bottom: calc( ( var(--hamburger-layer-spacing) + var(--hamburger-layer-height) ) * -1 );
    }
}

/*
  3dx,
  3dx-r,
  3dy,
  3dy-r,
  arrow,
  arrow-r,
  arrowalt,
  arrowalt-r,
  boring,
  collapse,
  collapse-r,
  elastic,
  elastic-r,
  emphatic,
  emphatic-r,
  slider,
  slider-r,
  spring,
  spring-r,
  stand,
  stand-r,
  spin,
  spin-r,
  squeeze,
  vortex,
  vortex-r
*/

/* Hamburger Types */
/* ================================================== */

/* Spin */
.c-hamburger--spin {
    .c-hamburger__inner {
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &::before {
            transition: top 0.1s 0.34s ease-in,
            opacity 0.1s ease-in;
        }

        &::after {
            transition: bottom 0.1s 0.34s ease-in,
            transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }
}
.c-hamburger--spin[aria-expanded='true'],
.c-hamburger--spin.is-active {
    .c-hamburger__inner {
        transform: rotate(225deg);
        transition-delay: 0.14s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::before {
            top: 0;
            opacity: 0;
            transition: top 0.1s ease-out,
            opacity 0.1s 0.14s ease-out;
        }

        &::after {
            bottom: 0;
            transform: rotate(-90deg);
            transition: bottom 0.1s ease-out,
            transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
    }
}
