a {
    text-decoration: none;
    color: var(--link);

    &:hover,
    &:focus {
        color: var(--link-hover);
    }

}
