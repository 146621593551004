.bgc-white {
    background-color: var(--white);
}
.bgc-1 {
    background-color: var(--brand-dark-gray);
}
.bgc-2 {
    background-color: var(--brand-gray);
}
.bgc-3 {
    background-color: var(--brand-green);
}
.bgc-4 {
    background-color: var(--brand-green-alt);
}
.bgc-5 {
    background-color: var(--brand-green-alt-15);
}
.bgc-6 {
    background-color: var(--brand-green-alt-08);
}

.bgc-5-before {
    position: relative;
    z-index: 0;

    &:before {
        content: "";
        background-color: var(--brand-green-alt-15);
        height: 3.5em;
        position: absolute;
        top: 0; right: 0; left: 0;
        z-index: -1;
    }
}



.tc-white {
    color: var(--white);
}
.tc-1 {
    color: var(--brand-dark-gray);
}
.tc-2 {
    color: var(--brand-gray);
}
.tc-3 {
    color: var(--brand-green);
}
.tc-4 {
    color: var(--brand-green-alt);
}
.tc-5 {
    color: var(--brand-green-alt-15);
}
.tc-6 {
    color: var(--brand-green-alt-08);
}
