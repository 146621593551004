.o-list {
    margin: 0;
    padding: 0;
}

    .o-list-reset {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .o-list-inline {
        & li {
            display: inline-block;
        }
    }
