.c-breadcrumb {
    font-size: 80%;
    list-style: none;
    margin: 0;
    padding-top: 2em;
    padding-right: 0;
    border-bottom: 1px solid var(--brand-green-alt);
    padding-bottom: 1em;
    padding-left: 0;
}

    .c-breadcrumb__item {
        display: inline-block;

        &:not(.c-breadcrumb__item--last) {
            font-weight: bold;
        }
        &:not(.c-breadcrumb__item--last):after {
            content: "/";
            font-weight: bold;
            margin-right: .5em;
            margin-left: .5em;
        }
    }



